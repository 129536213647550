import React from 'react'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ explanation }) => {
  if (!explanation || explanation.isHide) return null

  return (
    <div className={styles.explanation}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{explanation.title}</h2>
          {explanation.buttonText && explanation.buttonUrl && (
            <Link className={styles.button} to={explanation.buttonUrl}>
              {explanation.buttonText}
            </Link>
          )}
        </div>

        <div className={styles.video}>
          <iframe
            src={`https://www.youtube.com/embed/${explanation.youtubeVideoId}`}
            frameBorder="0"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </div>
  )
}
