import React from 'react'
import { sanityImageUrl, prepareParagraph } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ ingredients }) => {
  if (!ingredients || ingredients.isHide) return null

  return (
    <div className={styles.ingredients}>
      <div className={styles.wrap}>
        <p className={styles.titleSmall}>{ingredients.smallTitle}</p>
        <h2 className={styles.title}>{ingredients.title}</h2>
        <p className={styles.description}>{ingredients.description}</p>

        <div className={styles.items}>
          {ingredients.items &&
            ingredients.items.map(ingredient => (
              <div className={styles.item} key={ingredient._key}>
                <img src={sanityImageUrl(ingredient.image)} className={styles.image} alt={ingredient.image?.caption} />
                <p className={styles.name}>{ingredient.title}</p>

                <div className={styles.faq}>
                  {ingredient.faq &&
                    ingredient.faq.map(faq => (
                      <div key={faq._key}>
                        <p className={styles.faqQuestion}>{faq.question}</p>
                        <p className={styles.faqanswer}>{prepareParagraph(faq.answer)}</p>
                      </div>
                    ))}
                </div>

                {ingredient.buttonText && ingredient.buttonUrl && (
                  <Link to={ingredient.buttonUrl} className={styles.link}>
                    {ingredient.buttonText}
                  </Link>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
